"use client";

import { useIsomorphicLayoutEffect } from "react-use";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const GSAPRegister = () => {
    useIsomorphicLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger, useGSAP);
    }, []);

    return null;
};
