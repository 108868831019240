import { DEFAULT_LOCALE } from "@Shared/config";

export const DEFAULT_LANGUAGE =
    {
        en: "en",
        ru: "ru",
    }[DEFAULT_LOCALE as string] ?? "en";

export const DEFAULT_NS = "common";

export function getOptions() {
    return {
        supportedLngs: ["en", "ru"],
        fallbackLng: DEFAULT_LANGUAGE,
        lng: DEFAULT_LANGUAGE,
        fallbackNS: DEFAULT_NS,
        defaultNS: DEFAULT_NS,
        ns: ["common", "page-projects"],
    };
}
