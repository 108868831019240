"use client";

import { FC, PropsWithChildren } from "react";

import { useSetScrollbarWidth, useVUnits } from "@Shared/hooks";

import { GSAPRegister } from "./GSAPRegister";

export const ClientWrapper: FC<PropsWithChildren> = ({ children }) => {
    useSetScrollbarWidth();
    useVUnits();

    return (
        <>
            <GSAPRegister />
            {children}
        </>
    );
};
